/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
@import "../helper/sass-lib";

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "general";
@import "../milligram/Color";
@import "../helper/popup";
.work{
	.list{
		margin-bottom: 30px;
		.inner{
			height: 100%;
			display: block;
			border: 1px solid #D9D9D9;
			box-sizing: border-box;
			border-radius:8px;
			overflow: hidden;
		}
		h5{ 
			margin-bottom:10px;  
			font-weight: 600;
			color: $color-primary;
			font-size: 19px;
		}
		time{
			font-size: 13px;
			color:#0F232F;
		}
		figure{
			overflow: hidden;
			img{
				width: 100%;
				transition: .2s all ease-out;
			}
		}
		.text{
			padding: 15px;
		}
		&:hover{
			figure{
				img{
					transform: scale(1.05);
				}
			}
		}
	}
	.wrap-btn{
		text-align: center;
		margin: 80px auto 0;
	}
	.resource{
		figure{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 30px 5px;
			box-sizing: border-box;
			overflow: hidden;
			width: 100%;
		}
		.text{
			time{
				color: $color-secondary;
				font-weight: 500;
			}
			h5{
				max-height: 65px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: wrap;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
	}
}

.detail-aside{
	display: flex;
	aside{
		flex: 0 0 200px;
		max-width: 200px;
		ul{
			li{
				list-style: none;
				margin-bottom:30px;
			}
		}
		h5{ 
			margin-bottom: 5px; 
			font-size: 1rem;
			line-height: normal;
		}
	}
	.right-content{
		flex: 0 0 calc(100% - 200px);
		max-width: calc(100% - 200px);
		padding-left: 60px;
		box-sizing: border-box;
		h2{
			font-weight: 600;
			color: $color-primary;
			margin-bottom: 5px;
			line-height: normal;
		}
		.loc{ color: #EF3E56; font-size: 20px; }
	}
}

.slider-resource{
	position: relative;
	margin-bottom: 20px;
    overflow: hidden;
	border-radius: 8px;
	.slick-arrow{
		position: absolute;
		left: 25px;
		top: 40%;
		font-size: 0px;
		width: 48px;
		height: 48px;
		border-radius: 100%;
		display: block;
		line-height: 1;
		border: none;
		padding: 0px;
		background:#fff url('/images/material/arr-slider.svg')no-repeat center;
		background-size: 10px;
		box-shadow: 0 4px 20px -15px #000;
		z-index: 2;
		&:hover{
			box-shadow: 0 4px 20px -10px #000;
		}
		&.slick-next{
			left: auto;
			right: 25px;
			transform: rotate(180deg);
			box-shadow: 0 -4px 20px -15px #000;
			&:hover{
				box-shadow: 0 -4px 20px -10px #000;
			}
		}
	}
	.text{
		padding-top: 25px;
		h5{ margin-bottom: 5px;  font-weight: 600;}
		time{ color: $color-secondary; }
	}
}


@media (max-width: 1023px) {
	.work{
		.wrap-btn{ margin-top: 40px; }
		.list{
			figure{
				height: 160px;
				img{
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover; 
				}
			}
			h5 {
				font-size: 16px;
			}
		}
		.resource{
			.list{
				figure{
					height: auto;
					padding: 20px 15px;
				}
			}
		}
	}
	.detail-aside{
		.right-content{
			padding-left: 20px;
			time{
				font-size: 16px;
			}
		}
	}
}

@media (max-width: 767px) {
	.work-list{
		.column.column-33{
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.detail-aside{
		flex-flow: row wrap;
		aside{
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: 20px;
			ul{
				display: flex;
				flex-flow: row wrap;
				margin: 0 -5px;
				li{
					flex: 0 0 33.333%;
					max-width: 33.333%;
					padding: 0 5px;
					margin-bottom: 10px;
					box-sizing: border-box;
					h5{
						font-size: 14px;
						b{ font-size: 12px; }
					}
				}
			}
		}
		.right-content{
			flex: 0 0 100%;
			max-width: 100%;
			padding-left: 0px;
			.loc{
				font-size: 14px;
			}
		}
	}
	.slider-resource{
		.slick-arrow{
			width: 24px;
			height: 24px;
			background: #fff;
			border-radius: 100%;
			padding: 10px;
			margin-bottom: 0px;
			box-sizing: border-box;
			box-shadow: 0 5px 15px -7px #000;
			&:hover{
				box-shadow: 0 5px 15px -7px #000;
			}
			&:after{
				content:'';
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				width:6px;
				height: 10px;
				margin: auto;
				background: url('/images/material/arr-slider.svg')no-repeat center;
				background-size: contain;
			}
			&.slick-next{
				box-shadow: 0 -5px 15px -7px #000;
				&:hover{
					box-shadow: 0 -5px 15px -7px #000;	
				}
			}
		}
	}
}
