/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
@import "../helper/sass-lib";

/* general
----------------------------------------------------------------------------------------------*/
.main-content{
    padding: 150px 0 110px;
    p{
        color: rgba(15,35,47,.7);
        line-height: 1.5;
        margin-bottom: 20px;
    }
}
.title-border{
    position: relative;
    padding-top: 16px;
    margin-bottom: 20px;
    &::before{
        content: "";
        position: absolute;
        width: 48px;
        height: 4px;
        left: 0;
        top: 0;
        background: #EF3E56;
    }
    h2{
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 1.2;
        color: #0F232F;
        margin-bottom: 20px;
    }
}
.std-content{
    img{
        border-radius: 8px;
        display: block;
        margin-bottom: 40px;
    }
    figure{
      img{
        display: inline-block;
      }
    }
    a{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 190%;
        text-decoration-line: underline;
        color: #EF3E56;
        opacity: 0.8;
        transition: .2s all ease-out;
        &:hover{
            opacity: 1;
            color: #D78809;
        }
    }
    ul{
        list-style: none;
        margin-bottom: 0;
        li{
            position: relative;
            padding-left: 30px;
            color: rgba(15,35,47,.7);
            &:before{
                position: absolute;
                left: 0;
                top: 10px;
                content: "";
                width: 10px;
                height: 10px;
                background: #0F232F;
                border-radius: 100%;
            }
            &:last-of-type{
              margin-bottom: 5px;
            }
        }
    }
    iframe{
      width: 100%;
    }
}
.img-content{
    margin: 20px 0px;
    overflow: hidden;
    border-radius: 8px;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &.full{
        height: unset;
        img{
            display: block;
            width: 100%;
        }
    }
}
.site-link{
    margin-bottom: 56px;
    a{
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        color: #0F232F;
        opacity: 0.5;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 40px;
        display: inline-block;
        margin-left: 15px;
        transition: .2s all ease-out;
        &:first-child{
            margin-left: 0;
        }
        &.active{
            background: #EF3E56;
            opacity: 1;
            color: #F2F2F2;
        }
        &:hover{
            background: #EF3E56;
            opacity: 1;
            color: #F2F2F2;
        }
    }
}
.filter-middle{
    margin: 0px 0px 40px;
    width: 100%;
    .column{
        padding: 0px;
        &:first-child{
            select{
                border-left:1px solid #D9D9D9;
                border-radius: 8px 0 0 8px;
            }
        }
        &:last-child{
            select{
                border-radius: 0 8px 8px 0;
            }
        }
    }
    .box{
        position: relative;
    }
    label{
        position: absolute;
        left: 30px;
        top: 15px;
        right: 30px;
        font-size: 1rem;
        color: #757171;
        font-weight: 400;
        margin-bottom: 0px;
        z-index: -1;
    }
    select{
        border-radius: 0px;
        margin-bottom: 0px;
        height: auto;
        padding: 48px 50px 15px 30px;
        font-size: 19px;
        line-height: 1.5;
        color: #111;
        border-color:#D9D9D9;
        border-left: none;
        cursor: pointer;
        box-sizing: border-box;
        background: url('/images/material/arr-down.svg')no-repeat 95% 75%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        option{
            font-size: 1.2rem;
        }
    }
}
.load{
    text-align: center;
    .load-more{
        text-align: center;
        padding: 15px 110px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #EF3E56;
        border: 1px solid #EF3E56;
        box-sizing: border-box;
        border-radius: 8px;
        display: inline-block;
        cursor: pointer;
        transition: .2s all ease-out;
        &:hover{
            transition: .2s all ease-out;
            color:#fff;
            background: #EF3E56;
            border: 1px solid #fff;
        }
    }
}
.space-section{
    margin: 50px 0px;
}
.category-team{
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 64px;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px 30px;
    h5{
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 28px;
        color: #757171;
        margin: 0;
        padding-right: 15px;
    }
    select{
        max-width: 70%;
        width: auto;
        text-align: right;
        margin-bottom: 0px;
        border: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 1.5;
        color: #0F232F;
        height: auto;
        padding: 0;
        padding-right: 30px;
        background: url('/images/material/arr-down.svg')no-repeat right;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align-last: right;
        cursor: pointer;
    }
}
@media (max-width: 1500px) {
    .site-link{
        a{ padding: 10px 25px; }
    }
    
}

@media (max-width: 1023px) {
    .title-border{
        h2{ font-size: 1.75rem; }
    }
    h1 {
        font-size: 2.25rem
    }
    .std-content{
        p,a{ font-size: 16px; }
        h5{ font-size: 16px; margin-bottom: 10px; }
        ul{
            li{ 
              font-size: 16px;
              line-height:1.5;
              padding-left: 20px;
              &:before{
                width: 7px;
                height: 7px;
                top: 7px;
              }
            }
        }
    }
    .site-link{
        a{ font-size: 16px; }
    }
    .filter-middle{      
        label{ font-size: 14px; left: 15px; }
        select{
            font-size: 16px;
            padding: 40px 30px 15px 15px;
            background-position: 92% 65%;
            height: 84px;
            box-sizing: border-box;
        }
        .column{ 
            margin-bottom: 0px; 
            &:first-child{
                select{ border-radius:8px 0 0 8px; }
            }
            &:last-child{
                select{ border-radius:0 8px 8px 0px; }
            }
        }
    }
    .main-content{
        p{ 
            font-size: 16px;
         }
    }
    .category-team{
        padding: 10px 20px;
        select,h5{ font-size: 16px; }
    }
}
@media (max-width: 767px) {
    .title-border{
        margin-bottom: 15px;
        h2{ font-size: 24px; margin-bottom: 0px; }
    }
    h1 {
        font-size: 2rem
    }
    .main-content{
        overflow: hidden;
         padding:80px 0 70px;
        p,a{ font-size: 14px; }
    }
    .site-link{
        margin-right: -16px;
        margin-bottom: 10px;
        overflow-x: auto;
        display: flex;
        flex-flow: row nowrap;
        padding-bottom: 20px;
        a{
            white-space: nowrap;
            font-size: 14px;
            padding: 5px 15px;
        }
    }
    .img-content{
        margin: 20px 0px;
    }
    .filter-middle{
        overflow: hidden;
        flex-flow: row wrap;
        select{
            border: 1px solid #D9D9D9;
            border-bottom: none;
            height: 80px;
            padding: 40px 40px 15px 15px;
            background-position:97% 75%;
        }
        .column{
            border-left: none;
            &.column-33,&.column-50{
                flex: 0 0 100%;
                max-width: 100%;
            }
            &:first-child{
               select{
                 border-radius: 8px 8px 0px 0px;
                 border-top: 1px solid #D9D9D9;
                 border-left: 1px solid #D9D9D9;
               }
            }
            &:last-child{
               select{
                border-radius: 0px 0px 8px 8px;
                border-bottom: 1px solid #D9D9D9;
               }
            }
        }
    }
    .std-content{
        h4{ 
            font-size: 21px; 
            line-height: 1.4;
        }
        ul{
            li{
                font-size: 14px;
                padding-left: 15px;
                &:before{ top:4px; }
            }
        }
    }
    .category-team{
        margin-bottom: 25px;
        select,h5{ font-size: 12px; letter-spacing: 1.2px; }
        select {
            max-width: 53%;
        }
    }
}